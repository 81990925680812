exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-autor-stefano-js": () => import("./../../../src/pages/autor/stefano.js" /* webpackChunkName: "component---src-pages-autor-stefano-js" */),
  "component---src-pages-declaracao-de-uso-de-cookies-js": () => import("./../../../src/pages/declaracao-de-uso-de-cookies.js" /* webpackChunkName: "component---src-pages-declaracao-de-uso-de-cookies-js" */),
  "component---src-pages-guest-post-js": () => import("./../../../src/pages/guest-post.js" /* webpackChunkName: "component---src-pages-guest-post-js" */),
  "component---src-pages-politica-de-privacidade-e-seguranca-js": () => import("./../../../src/pages/politica-de-privacidade-e-seguranca.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-e-seguranca-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-category-js": () => import("./../../../src/templates/blog-post-category.js" /* webpackChunkName: "component---src-templates-blog-post-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-user-js": () => import("./../../../src/templates/blog-post-user.js" /* webpackChunkName: "component---src-templates-blog-post-user-js" */),
  "component---src-templates-stefano-posts-js": () => import("./../../../src/templates/stefano-posts.js" /* webpackChunkName: "component---src-templates-stefano-posts-js" */)
}

